import React from "react"
import { graphql } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { Layout } from '../../components/Layout'
import { components } from '../../slices'

function PortfolioItem(props) {
  console.log('PortfolioItem props: ', props)
  const { prismicPortfolioItem, prismicPortfolio } = props.data
  const portfolioItem = prismicPortfolioItem.data
  // const eventInfoSlice = event.body.find(item => item.slice_type === 'event_info');
  const seo = {
    title: prismicPortfolioItem.data.seo_title || prismicPortfolioItem.data.title.text,
    description: prismicPortfolioItem.data.seo_description,
    keywords: prismicPortfolioItem.data.seo_keywords,
    no_robots: prismicPortfolioItem.data.no_robots,
  }
  console.log('seo: ', seo)

  const {title, desc_short, body} = portfolioItem
  const cntx = {title, desc_short};
  console.log('cntx: ', cntx)

  const topMenu = props.data.prismicTopmenu || {}
  const footer = props.data.prismicFooter || {}

  const isTMWhite = true

  return (
    <Layout topMenu={topMenu.data} isTMWhite={isTMWhite} footer={footer} seo={seo}>
      <SliceZone slices={body} components={components} context={cntx}/>
    </Layout>
  )
}

export default PortfolioItem

export const query = graphql`
  query($id: String!) {
    prismicPortfolioItem(id: { eq: $id }) {
      id
      type
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title
        desc_short {
          richText
        }
        body {
          ...PrismicPortfolioItemPortfolioItemInfo
        }
      }
    }
    prismicTopmenu {
      ...TopmenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`