exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-prismic-blog-item-uid-js": () => import("./../../../src/pages/blog/{prismicBlogItem.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-blog-item-uid-js" */),
  "component---src-pages-portfolio-prismic-portfolio-item-uid-js": () => import("./../../../src/pages/portfolio/{prismicPortfolioItem.uid}.js" /* webpackChunkName: "component---src-pages-portfolio-prismic-portfolio-item-uid-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */)
}

