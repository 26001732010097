import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import moment from 'moment';

import { Layout } from '../components/Layout'

const PortfolioTemplate = ({ data }) => {
  console.log('PortfolioTemplate data: ', data);
  
  const [portfolio,setPortfolio] = useState([])
  
  useEffect( ()=> {
    const docs = data.allPrismicPortfolioItem || []
    const now = moment()
    const filtered = docs.nodes.filter(item => {
      console.log('item: ', item)
      const data = item.data
      // console.log('start: ',data.start_time)
      // console.log('finish: ',data.finish_time)
      // const startTime = moment(data.start_time)
      // const finishTime = moment(data.finish_time)
      // return (startTime < now)
      return true;
    })
    console.log('portfolio: ', filtered)
    setPortfolio(filtered)
  }, [data.allPrismicPortfolioItem])
  
  if (!data) return null

  const homepage = data.prismicPortfolio || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
    no_robots: homepage.data.no_robots,
  }
  console.log('seo: ', seo)

  const topMenu = data.prismicTopmenu || {}
  const footer = data.prismicFooter || {}

  const isTMWhite = true

  const PortfolioItem = ({item}) => {
    const portfolioInfo = item.data.body.find(item => item.slice_type === 'portfolio_item_info')
    const portfolioLink = `/portfolio/${item.uid}`
    return (
      <a className="port-item bg-light" style={{aspectRatio:"1"}} href={portfolioLink}>
        <div className="port-img-overlay" style={{aspectRatio:"1"}}>
          <img className="port-main-img" src={item.data.cover_img.url} alt="img"/>
        </div>
        <div className="port-overlay-cont">
          <a className="text-decoration-none" href={portfolioLink}><h5 className="h5 fw-bold text-white text-uppercase">{item.data.title}</h5></a>
          <a className="text-white text-decoration-none" href={portfolioLink}>
            <PrismicRichText field={item.data.desc_short.richText} />
          </a>
        </div>
      </a>
    )
  }

  return (
    <Layout topMenu={topMenu.data} isTMWhite={isTMWhite} footer={footer} seo={seo}>
      <div className="bg-light">
        <div className="container" style={{paddingTop:"120px",paddingBottom:"80px"}}>
          <h1 className="page-title" style={{marginBottom:"56px"}}>{homepage.data.title}</h1>
          <div className="bg-white row p-4 gy-3 gx-0 gx-sm-3 mx-0">
            {
              portfolio.map((item, index) => (
                <div key={index} className="col-12 col-sm-6 col-lg-4">
                  <PortfolioItem item={item} />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyPortfolioQuery {
    prismicPortfolio {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title  
      }
      url
      type
    }

  prismicTopmenu {
    ...TopmenuFragment
  }
  prismicFooter {
    ...FooterFragment
  }

  allPrismicPortfolioItem(
    sort: { fields: [data___sort], order: ASC }
    limit: 10
    skip: 0
  ) {
    nodes {
      _previewable
      uid
      url
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title
        desc_short {
          richText
        }
        cover_img {
          url
        }
        sort
        body {
          ... on PrismicPortfolioItemDataBodyPortfolioItemInfo {
            id
            slice_type
            primary {
              desc {
                text
                richText
              }
            }
          }
        }
      }
    }
  }
}
`

export default withPrismicPreview(PortfolioTemplate)
